<template>
    <div>
        Feedback & Inquiry
    </div>
</template>
<script>

export default ({
    name: 'index',
    data() {
        return {

        }
    }
})
</script>

<style scoped lang="scss"></style>